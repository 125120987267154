import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Img, { GatsbyImageProps } from 'gatsby-image';

import borderUrl from '../../assets/images/gallery_image_border.svg';
import arrowUrl from '../../assets/images/gallery_arrow_border.svg';
import leftArrow from '../../assets/images/gallery_left_arrow.png';
import rightArrow from '../../assets/images/gallery_right_arrow.png';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: none;
  }
`;

const StyledArrowBorder = styled.div`
  display: inline-block;
  margin: 0 1rem;
  ${props => props.theme.borderMask(arrowUrl)};
`;

const StyledArrowButton = styled.button`
  height: 2.5em;
  width: 2.5rem;
  background: ${props => props.theme.white};
  border: none;
  cursor: pointer;
  ${props => props.theme.mask(arrowUrl)};
`;

const StyledArrowImg = styled.img`
  height: 1rem;
`;

const StyledThumbnails = styled.div`
  display: flex;
  max-width: 80%;
  padding-right: 1rem;
  overflow-x: scroll;
`;

const StyledThumbnailWrapper = styled.div`
  display: inline-block;
  padding: 0 1rem;
`;

const StyledThumbnailBorder = styled.div`
  cursor: pointer;
  ${props => props.theme.borderMask(borderUrl)};
`;

type StyledImgProps = {
  $isCurrent: boolean;
};

const StyledImg = styled(Img)<StyledImgProps>`
  height: 5rem;
  width: 5rem;
  ${props => props.theme.mask(borderUrl)};

  ${props =>
    !props.$isCurrent &&
    css`
      opacity: 0.5;
    `};
`;

interface GalleryNavProps {
  images: {
    childImageSharp: GatsbyImageProps;
  }[];
  currImageIndex: number;
  setCurrImageIndex: Dispatch<SetStateAction<number>>;
  handlePrevClick: () => void;
  handleNextClick: () => void;
}

const GalleryNav: React.FC<GalleryNavProps> = props => {
  const {
    images,
    currImageIndex,
    setCurrImageIndex,
    handlePrevClick,
    handleNextClick,
  } = props;

  const handleKeyDown = useCallback(
    e => {
      if (e.key == 'ArrowRight') {
        e.preventDefault();
        handleNextClick();
      } else if (e.key == 'ArrowLeft') {
        e.preventDefault();
        handlePrevClick();
      }
    },
    [handleNextClick, handlePrevClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return (
    <StyledContainer>
      <StyledArrowBorder>
        <StyledArrowButton>
          <StyledArrowImg src={leftArrow} onClick={handlePrevClick} />
        </StyledArrowButton>
      </StyledArrowBorder>
      <StyledThumbnails>
        {images.map((image, index) => (
          <StyledThumbnailWrapper key={index} id={`thumbnail-${index}`}>
            <StyledThumbnailBorder onClick={() => setCurrImageIndex(index)}>
              <StyledImg
                fluid={{ ...image.childImageSharp.fluid }}
                $isCurrent={currImageIndex == index}
              />
            </StyledThumbnailBorder>
          </StyledThumbnailWrapper>
        ))}
      </StyledThumbnails>

      <StyledArrowBorder>
        <StyledArrowButton>
          <StyledArrowImg src={rightArrow} onClick={handleNextClick} />
        </StyledArrowButton>
      </StyledArrowBorder>
    </StyledContainer>
  );
};

export default GalleryNav;
