import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import borderUrl from '../../assets/images/disco_embed_border.svg';

const StyledBorder = styled.div`
  height: 100%;
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledMask = styled.div`
  position: relative;
  height: 100%;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};
`;

const StyledLoading = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};
`;

const StyledContainer = styled.div`
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;

    @media (max-width: ${props => props.theme.breakMedium}) {
      height: 40rem;
    }
  }
`;

interface LiveRecordingDetailsProps {
  embed: string;
}

const LiveRecordingDetails: React.FC<LiveRecordingDetailsProps> = props => {
  const { embed } = props;

  const [loading, setLoading] = useState(true);

  const iframeOnLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    document
      .getElementsByClassName('disco-embed')[0]
      .addEventListener('load', iframeOnLoad);

    return () => {
      document
        .getElementsByClassName('disco-embed')[0]
        .removeEventListener('load', iframeOnLoad);
    };
  }, []);

  return (
    <StyledBorder>
      <StyledMask>
        {loading && <StyledLoading>Loading...</StyledLoading>}
        <StyledContainer dangerouslySetInnerHTML={{ __html: embed }} />
      </StyledMask>
    </StyledBorder>
  );
};

export default LiveRecordingDetails;
