import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import borderUrl from '../../assets/images/disco_embed_border.svg';

const StyledBorder = styled.div`
  width: 100%;
  ${props => props.theme.borderMask(borderUrl)};
`;

type StyledMaskProps = {
  $width: number;
};

const StyledMask = styled.div<StyledMaskProps>`
  position: relative;
  background: ${props => props.theme.white};
  height: calc(9 / 16 * ${props => props.$width}px);
  ${props => props.theme.mask(borderUrl)};

  iframe {
    position: absolute;
    top: -1%;
    left: -1%;
    height: 102%;
    width: 102%;
  }
`;

const StyledLoading = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};
`;

interface LiveVideoDetailsProps {
  videoId: string;
}

const LiveVideoDetails: React.FC<LiveVideoDetailsProps> = props => {
  const { videoId } = props;

  const iframe = useRef(null);
  const container = useRef(null);
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(container?.current.clientWidth);
  }, [container]);

  const iframeOnLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    iframe.current.addEventListener('load', iframeOnLoad);

    return () => {
      iframe.current.removeEventListener('load', iframeOnLoad);
    };
  }, []);

  return (
    <StyledBorder ref={container}>
      <StyledMask $width={width}>
        {loading && <StyledLoading>Loading...</StyledLoading>}
        <iframe
          ref={iframe}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </StyledMask>
    </StyledBorder>
  );
};

export default LiveVideoDetails;
