import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Image from './Image';
import GalleryNav from './GalleryNav';

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 100%;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: block;
  }
`;

const StyledImageContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: none;
  }
`;

type StyledHalfProps = {
  $isLeft?: boolean;
};

const StyledHalf = styled.div<StyledHalfProps>`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;

  ${props =>
    props.$isLeft
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
`;

const StyledAllImages = styled.div`
  display: none;

  > {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: block;
  }
`;

interface GalleryProps {
  content: Collections.Photo | Collections.Polaroid | Collections.Poster;
}

const Gallery: React.FC<GalleryProps> = props => {
  const {
    content: { main_image, other_images },
  } = props;

  const images = [main_image, ...(other_images ?? [])];
  const [currImageIndex, setCurrImageIndex] = useState<number>(0);

  const handlePrevClick = useCallback(() => {
    const newIndex =
      currImageIndex == 0 ? images.length - 1 : currImageIndex - 1;
    setCurrImageIndex(newIndex);
    document.getElementById(`thumbnail-${newIndex}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, [currImageIndex, images.length, setCurrImageIndex]);

  const handleNextClick = useCallback(() => {
    const newIndex =
      currImageIndex == images.length - 1 ? 0 : currImageIndex + 1;
    setCurrImageIndex(newIndex);
    document.getElementById(`thumbnail-${newIndex}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, [currImageIndex, images.length, setCurrImageIndex]);

  const container = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setDimensions({
      width: container?.current.clientWidth,
      height: container?.current.clientHeight,
    });
  }, [container]);

  return (
    <StyledContainer>
      <StyledImageContainer ref={container}>
        <Image image={images[currImageIndex]} dimensions={dimensions} />
        <StyledHalf $isLeft onClick={handlePrevClick} />
        <StyledHalf onClick={handleNextClick} />
      </StyledImageContainer>
      {images.length > 1 && (
        <GalleryNav
          images={images}
          currImageIndex={currImageIndex}
          setCurrImageIndex={setCurrImageIndex}
          handlePrevClick={handlePrevClick}
          handleNextClick={handleNextClick}
        />
      )}
      <StyledAllImages>
        {images.map((image, index) => (
          <Image key={index} image={image} />
        ))}
      </StyledAllImages>
    </StyledContainer>
  );
};

export default Gallery;
