import React, { useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { Link } from 'gatsby';

import LiveRecordingDetails from './LiveRecordingDetails';
import LiveVideoDetails from './LiveVideoDetails';
import Gallery from './Gallery';
import Comments from './Comments';

import { CONTENT_TYPE } from '../../constants';

import showLabelUrl from '../../assets/images/photo_border.svg';
import creditUrl from '../../assets/images/photo_credit.svg';
import closeUrl from '../../assets/images/details_close.svg';
import getDatePath from '../../utils/getDatePath';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: initial;
`;

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledDetails = styled.div`
  position: relative;
  display: grid;
  grid-template:
    'content labels' auto
    'content comments' 1fr
    / auto 22rem;
  gap: 1rem 2rem;
  height: 100%;
  padding: 50px 2rem 70px;
  transition: all ease-out 0.3s;
  overflow-y: scroll;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakLarge}) {
    grid-template-columns: auto 20rem;
    padding: 50px 1rem 70px;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: block;
    width: 85vw;
    margin: 0 auto;
    padding: calc(50px + 1rem) 0;
    text-align: center;
  }
`;

const StyledLabelContainer = styled.div`
  grid-area: labels;
`;

const StyledDetailsContainer = styled.div`
  grid-area: content;

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 100%;
    margin: 1rem 0;
  }
`;

const StyledComments = styled(Comments)`
  grid-area: comments;
`;

const StyledCloseButtonBorder = styled.div`
  ${props =>
    props.$topRight
      ? css`
          display: inline-block;
          position: absolute;
          top: 0.2rem;
          right: 1rem;
        `
      : css`
          display: none;
        `};
  ${props => props.theme.borderMask(closeUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: ${props => (props.$topRight ? 'none' : 'inline-block')};
    margin-bottom: 1rem;
  }
`;

const StyledCloseButton = styled.input`
  background: ${props => props.theme.white};
  padding: 1rem 0.5rem 0.5rem 0.6rem;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(closeUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 1.3rem 0.8rem 0.8rem 0.9rem;
    font-size: 1.875rem;
  }
`;

const StyledShowLabels = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${props => props.theme.black};

  > * {
    margin-bottom: 0.2rem;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    align-items: center;
  }
`;

type StyledBorderProps = {
  borderUrl: string;
};

const StyledBorder = styled.div<StyledBorderProps>`
  display: inline-block;
  ${props => props.theme.borderMask(props.borderUrl)};
`;

type StyledTextProps = {
  maskUrl: string;
};

const StyledText = styled.div<StyledTextProps>`
  background: ${props => props.theme.white};
  padding: 0.4rem 0.7rem 0.2rem;
  font-size: 0.625rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(props.maskUrl)};
`;

const StyledCredit = styled.a`
  display: block;
  text-decoration: none;
  color: ${props => props.theme.black};
`;

interface DetailsProps {
  content: Common.ContentChildMdx;
  onCloseClick: () => void;
}

const Details: React.FC<DetailsProps> = props => {
  const {
    content,
    content: {
      frontmatter: { show, credit, credit_link },
      slug,
    },
    onCloseClick,
  } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  });

  const momentDate = moment(show.date);
  const dateObj = {
    year: momentDate.year().toString(),
    month: momentDate.month().toString(),
    date: momentDate.date().toString(),
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleKeyDown = useCallback(
    e => {
      if (e.key == 'Escape') {
        onCloseClick();
      }
    },
    [onCloseClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const renderContent = () => {
    switch (content.frontmatter.content_type) {
      case CONTENT_TYPE.ALBUM:
        return <p>album</p>;
      case CONTENT_TYPE.LIVE_RECORDING:
        return <LiveRecordingDetails embed={content.frontmatter.embed} />;
      case CONTENT_TYPE.LIVE_VIDEO:
        return <LiveVideoDetails videoId={content.frontmatter.video_id} />;
      case CONTENT_TYPE.PHOTO:
        return <Gallery content={content.frontmatter} creditText="Photo by " />;
      case CONTENT_TYPE.POLAROID:
        return <Gallery content={content.frontmatter} creditText="Photo by " />;
      case CONTENT_TYPE.POSTER:
        return (
          <Gallery content={content.frontmatter} creditText="Poster by " />
        );
      case CONTENT_TYPE.TEXT:
        return <p>text</p>;
    }
  };

  const getCreditText = () => {
    switch (content.frontmatter.content_type) {
      case CONTENT_TYPE.PHOTO:
      case CONTENT_TYPE.POLAROID:
        return 'Photo by ';
      case CONTENT_TYPE.POSTER:
        return 'Poster by ';
    }
  };

  return (
    <StyledOverlay onMouseDown={handleMouseDown}>
      <StyledBackdrop onClick={onCloseClick} />
      <StyledCloseButtonBorder $topRight>
        <StyledCloseButton type="button" value="x" onClick={onCloseClick} />
      </StyledCloseButtonBorder>
      <StyledDetails onClick={onCloseClick}>
        <StyledCloseButtonBorder>
          <StyledCloseButton type="button" value="x" onClick={onCloseClick} />
        </StyledCloseButtonBorder>
        <StyledLabelContainer onClick={handleMouseDown}>
          <StyledShowLabels
            to={`/timeline/${getDatePath(dateObj)}/${show.slug}`}
          >
            <StyledBorder borderUrl={showLabelUrl}>
              <StyledText maskUrl={showLabelUrl}>
                {momentDate.format('D MMMM YYYY')}
              </StyledText>
            </StyledBorder>
            <StyledBorder borderUrl={showLabelUrl}>
              <StyledText
                maskUrl={showLabelUrl}
              >{`${show.venue}, ${show.city}`}</StyledText>
            </StyledBorder>
          </StyledShowLabels>
          {credit && (
            <StyledCredit
              href={credit_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledBorder borderUrl={creditUrl}>
                <StyledText maskUrl={creditUrl}>
                  {getCreditText()}
                  {credit}
                </StyledText>
              </StyledBorder>
            </StyledCredit>
          )}
        </StyledLabelContainer>
        <StyledDetailsContainer onClick={handleMouseDown}>
          {renderContent()}
        </StyledDetailsContainer>
        <StyledComments slug={slug} />
      </StyledDetails>
    </StyledOverlay>
  );
};

export default Details;
