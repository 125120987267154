import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div``;

interface DragScrollWrapperProps {
  className?: string;
  isWall?: boolean;
}

const DragScrollWrapper: React.FC<DragScrollWrapperProps> = props => {
  const { children, className, isWall } = props;

  const container = useRef(null);
  const pos = useRef({ top: 0, left: 0, x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);

  const mouseMoveHandler = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // How far the mouse has been moved
      const dx = e.clientX - pos.current.x;
      const dy = e.clientY - pos.current.y;

      const target = isWall ? window : container.current;
      target?.scrollTo(pos.current.left - dx, pos.current.top - dy);
      setIsMoving(true);
    },
    [isWall]
  );

  const mouseUpHandler = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (container.current) {
        container.current.style.cursor = 'grab';
        container.current.style.removeProperty('user-select');
      }
      window.removeEventListener('mousemove', mouseMoveHandler);
    },
    [mouseMoveHandler]
  );

  const mouseDownHandler = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      container.current.style.cursor = 'grabbing';
      container.current.style.userSelect = 'none';

      pos.current = {
        // The current scroll
        left: isWall ? window.scrollX : container.current.scrollLeft,
        top: isWall ? window.scrollY : container.current.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      window.addEventListener('mousemove', mouseMoveHandler);
      window.addEventListener('mouseup', mouseUpHandler);
    },
    [isWall, mouseMoveHandler, mouseUpHandler]
  );

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isMoving) {
        e.preventDefault();
        e.stopPropagation();
      }
      setIsMoving(false);
    },
    [isMoving]
  );

  return (
    <StyledContainer
      ref={container}
      id="container"
      className={className}
      onMouseDown={mouseDownHandler}
      onClickCapture={clickHandler}
    >
      {children}
    </StyledContainer>
  );
};

export default DragScrollWrapper;
