export const CONTENT_TYPE = {
  ALBUM: 'album',
  LIVE_RECORDING: 'live_recording',
  LIVE_VIDEO: 'live_video',
  PHOTO: 'photo',
  POLAROID: 'polaroid',
  POSTER: 'poster',
  TEXT: 'text',
};

export const TIMELINE_STATE = {
  ALBUM: 'album',
  DATE_SELECT: 'date_select',
  MAIN: 'main',
  SHOW: 'show',
};

export const MONTHS = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const HYVOR_TALK_WEBSITE_ID = 4150;
