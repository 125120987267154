import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { CONTENT_TYPE } from '../../constants';
import Album from '../Album';
import LiveRecording from '../content_blocks/LiveRecording';
import LiveVideo from '../content_blocks/LiveVideo';
import Photo from '../content_blocks/Photo';
import Polaroid from '../content_blocks/Polaroid';
import Poster from '../content_blocks/Poster';
import Text from '../content_blocks/Text';

const StyledContainer = styled.div`
  display: inline-block;
  margin: 1rem;
  will-change: left, top;
`;

interface WallChildrenProps {
  content: Common.ContentChildMdx;
}

const WallContent: React.FC<WallChildrenProps> = function (props) {
  const {
    content: { frontmatter },
  } = props;

  switch (frontmatter.content_type) {
    case CONTENT_TYPE.ALBUM:
      return <Album content={frontmatter} />;
    case CONTENT_TYPE.LIVE_RECORDING:
      return <LiveRecording content={frontmatter} />;
    case CONTENT_TYPE.LIVE_VIDEO:
      return <LiveVideo content={frontmatter} />;
    case CONTENT_TYPE.PHOTO:
      return <Photo content={frontmatter} />;
    case CONTENT_TYPE.POLAROID:
      return <Polaroid content={frontmatter} />;
    case CONTENT_TYPE.POSTER:
      return <Poster content={frontmatter} />;
    case CONTENT_TYPE.TEXT:
      return <Text content={frontmatter} />;
  }

  return null;
};

interface WallObjectProps {
  content: Common.ContentChildMdx;
  setContent?: Dispatch<SetStateAction<Common.ContentChildMdx | null>>;
  className?: string;
}

const WallObject: React.FC<WallObjectProps> = props => {
  const { content, setContent, className } = props;

  const handleClick = () => {
    if (setContent) {
      if (
        content.frontmatter.content_type == CONTENT_TYPE.LIVE_RECORDING ||
        content.frontmatter.content_type == CONTENT_TYPE.LIVE_VIDEO ||
        content.frontmatter.content_type == CONTENT_TYPE.POSTER ||
        content.frontmatter.content_type == CONTENT_TYPE.PHOTO ||
        content.frontmatter.content_type == CONTENT_TYPE.POLAROID
      ) {
        window.history.pushState(
          null,
          document.title,
          `?content=${content.slug}`
        );
        setContent(content);
      }
    }
  };

  return (
    <StyledContainer onClick={handleClick} className={className}>
      <WallContent content={content} />
    </StyledContainer>
  );
};

export default React.memo(WallObject);
